<template>
  <section class="lands px-1 pt-3">
    <section class="d-flex">
      <!-- filters -->
      <section class="filters">
        <LandFilters @submitFilters="submitFilters" />
      </section>
      <!-- filters -->

      <section class="main-shop">
        <section class="d-flex">
          <mobile-filters @submitFilters="submitFilters"></mobile-filters>
          <LandSortBox
            @toggleDrawer="drawer = !drawer"
            @makeSort="makeSort"
            @resetSort="resetSort"
            :total="total"
          />
        </section>

        <section v-if="isLoading">
          <div class="no-item">
            <loading-spinner></loading-spinner>
          </div>
        </section>
        <div class="no-item" v-else-if="!isLoading && !lands.length">
          <h1 class="white--text">Nothing To show !</h1>
        </div>

        <v-row class="row" no-gutters v-else>
          <LandItem
            v-for="(item, i) in lands"
            :key="i"
            :item="item"
            @buyLand="buyLand"
            @showDetails="showDetails"
          />
        </v-row>
      </section>
    </section>

    <!-- pagination -->
    <div class="line mt-2"></div>
    <input-pagination
      :total="total"
      :page="page"
      :lastPage="lastPage"
      @passedPage="inputChangePage"
    ></input-pagination>
    <!-- <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          total-visible="5"
          @input="fetchLandLists()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div> -->
    <!-- pagination -->

    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="600">
      <DetailCard
        @close="detailDialog = false"
        :selectedItem="selectedLand"
        :isLoading="detailLoading"
        type="Land"
        @buy="buyLand"
      ></DetailCard>
    </v-dialog>
    <!--detail dialog -->

    <!--quastion dialog -->
    <v-dialog v-model="quastionDialog" width="500">
      <QuastionCard
        @cancel="quastionDialog = false"
        text="Are you sure to buy this item ? "
        :isLoading="buyLoading"
        @accept="accept"
      ></QuastionCard>
    </v-dialog>
    <!--quastion dialog -->

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      color="green accent-4 "
      dense
      centerd
      shaped
      top
      transition="slide-y-transition"
      style="position: fixed"
    >
      <div class="d-flex">
        {{ message }}
        <v-spacer></v-spacer>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </section>
</template>

<script>
import LandItem from "./LandItem.vue";
import LandFilters from "./LandFilters.vue";
import LandSortBox from "./LandSortBox.vue";
import { mapActions } from "vuex";
import MobileFilters from "./MobileFilters.vue";
export default {
  data() {
    return {
      lands: [],
      drawer: false,
      detailDialog: false,
      detailLoading: false,
      isLoading: false,

      selectedLand: {
        parentCategory: {
          title: "",
        },
        category: {
          title: "",
        },
        sale: {
          status: "",
          price: "",
          priceDescription: "",
        },
        map: {
          art_portraite: "",
        },
      },
      // filter
      selectedSubCategory: 0,
      selectedSpecifications: [],
      //size
      // widthRange: [0, 0],
      // heightRange: [0, 0],
      //size

      // name: null,
      // location
      cordinateXSelected: 0,
      cordinateYSelected: 0,
      selectedRadius: 0,
      // location

      // price
      priceRange: [0, 23300],
      // price
      // filter
      page: 1,
      lastPage: 0,
      total: 0,

      // sort
      selectedSort: null,
      selectedOrder: null,
      // sort

      snackbar: false,
      message: "",
      buyLoading: false,
      quastionDialog: false,
      selectedItemId: null,
    };
  },
  components: {
    LandItem,
    LandSortBox,
    LandFilters,
    MobileFilters,
  },

  created() {
    this.fetchLandLists();
  },
  methods: {
    ...mapActions(["getLandDetail", "getLands", "buy"]),

    fetchLandLists() {
      let params = {
        page: this.page,
        limit: 8,
        type: "land",
        category_id: this.selectedSubCategory,
        // name: this.name,
        coordinateX: this.cordinateXSelected,
        coordinateY: this.cordinateYSelected,
        radius: this.selectedRadius,
        from_price: this.priceRange[0],
        to_price: this.priceRange[1],
        tags: this.selectedSpecifications,
      };
      if (this.selectedSort != null) {
        params.sort = this.selectedSort;
        params.order = this.selectedOrder;
      }

      this.isLoading = true;
      this.getLands(params)
        .then((result) => {
          this.lands = result.data;
          console.log("lands:", this.lands);

          this.total = result.meta.total;
          this.lastPage = result.meta.last_page;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    submitFilters(
      selectedSubCategory,
      // name,
      cordinateXSelected,
      cordinateYSelected,
      selectedRadius,
      priceRange,
      selectedSpecifications
    ) {
      this.selectedSubCategory = selectedSubCategory;
      // this.name = name;
      this.cordinateXSelected = cordinateXSelected;
      this.cordinateYSelected = cordinateYSelected;
      this.selectedRadius = selectedRadius;
      this.priceRange = priceRange;
      this.selectedSpecifications = selectedSpecifications;
      this.fetchLandLists();
    },

    makeSort(order, sort) {
      this.selectedSort = sort;
      this.selectedOrder = order;
      this.fetchLandLists();
    },
    resetSort() {
      this.selectedSort = null;
      this.selectedOrder = null;
      this.fetchLandLists();
    },
    buyLand(item) {
      this.selectedItemId = item.id;
      this.quastionDialog = true;
    },
    accept() {
      this.buyLoading = true;
      this.buy(this.selectedItemId)
        .then((result) => {
          console.log(result);
          this.message = result.message;
          this.snackbar = true;
          this.fetchLandLists();
        })
        .finally(() => {
          this.buyLoading = false;
          this.quastionDialog = false;
        });
    },

    showDetails(item) {
      this.detailDialog = true;
      this.detailLoading = true;
      this.getLandDetail(item.record_detail.id).then((result) => {
        this.selectedLand = result.result;
        this.detailLoading = false;
      });
    },
    inputChangePage(val) {
      console.log(val);
      this.page = val;
      this.fetchLandLists();
    },
  },
};
</script>

<style lang="scss" scoped>
.lands {
  .filters {
    margin-top: -70px;
    border-right: 2px solid #6ee6ff;
    // overflow: hidden;
    @media only screen and(max-width: 980px) {
      display: none;
    }
  }
  .main-shop {
    // position: relative;
    width: 100%;
    min-width: 83%;
  }
}
</style>
