<template>
  <div class="auth-wrapper">
    <section class="auth-card">
      <h2 class="text-center white--text">Sign Up</h2>
      <p class="text-center mb-5 white--text">
        Get start with create an account
      </p>
      <v-form
        @submit.prevent="validate"
        ref="register"
        v-model="valid"
        lazy-validation
      >
        <div class="form-group">
          <label class="white--text">Name</label>
          <v-text-field
            type="text"
            :rules="rules.name_rules"
            v-model="form.name"
            outlined
            dense
            placeholder="Type your name"
            dark
          >
            <v-icon slot="append"> mdi-account </v-icon></v-text-field
          >
        </div>

        <div class="form-group">
          <label class="white--text">Email</label>
          <v-text-field
            type="email"
            :rules="rules.email_rules"
            v-model="form.email"
            outlined
            dense
            dark
            placeholder="Type your email"
          >
            <v-icon slot="append"> mdi-email </v-icon>
          </v-text-field>
        </div>

        <div class="form-group">
          <label class="white--text">Password</label>
          <v-text-field
            type="password"
            :rules="rules.password_rules"
            v-model="form.password"
            outlined
            dense
            dark
            placeholder="Type your password"
          >
            <v-icon slot="append"> mdi-lock </v-icon></v-text-field
          >
        </div>

        <div class="form-group">
          <label class="white--text">Confirmation Password</label>
          <v-text-field
            type="password"
            :rules="rules.password_rules.concat(passwordConfirmationRule)"
            v-model="form.password_confirmation"
            outlined
            dense
            dark
            placeholder="Type your password again"
          >
            <v-icon slot="append">mdi-lock-check</v-icon>
          </v-text-field>
          <div class="captcha-container d-flex">
            <VueRecaptcha
              class="captch"
              @verify="successCaptcha"
              @expired="expireCaptcha"
              :sitekey="sitekey"
            />
          </div>
        </div>

        <v-btn
          type="submit"
          class="login-btn mt-5 w-100"
          :loading="loading"
          :disabled="loading"
        >
          Sign Up
          <v-icon right> mdi-account-plus </v-icon>
        </v-btn>
      </v-form>
      <Or class="or-line"></Or>
      <GoogleLogin name="Continue with Google"></GoogleLogin>
      <div class="text-center mt-5">
        <p class="white--text">Dont have an account?</p>
        <routerLink :to="{ name: 'login' }" class="auth-link">
          Sign In
        </routerLink>
      </div>
    </section>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { captcha } from "@/mixins/captcha.js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      valid: true,
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  mixins: [captcha],
  components: { VueRecaptcha },
  computed: {
    ...mapGetters(["rules", "wallet_address"]),

    passwordConfirmationRule() {
      return () =>
        this.form.password === this.form.password_confirmation ||
        "Passwords must match";
    },
  },

  methods: {
    ...mapActions(["register"]),

    validate() {
      this.$refs.register.validate();
      if (this.captcha) {
        this.$nextTick(() => {
          if (this.valid) {
            this.loading = true;
            this.form.recaptcha = this.captcha
            this.register(this.form).then((res) => {
              if (res) {
                this.$router.push({ name: "Home" });
              }
              this.loading = false;
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  height: 110vh;
  background-image: url("../../assets/images/login/login-bg(1).jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .auth-card {
    width: 98%;
    max-width: 400px;
    background: rgba(0, 0, 0, 0.838);
    border-radius: 8px;
    padding: 20px 35px;
    margin-top: 60px;
    @media only screen and(max-width : 380px) {
      transform: scale(0.92);
      padding: 15px 25px;
    }
    .captcha-container {
      .captch {
        margin: 0 auto;
        @media only screen and(max-width : 380px) {
          transform: scale(0.9);
          transform-origin: left;
        }
        @media only screen and(max-width : 350px) {
          transform: scale(0.8);
        }
      }
    }
    .login-btn {
      background: var(--light-blue) !important;
      color: black;
      font-weight: bolder;
      transition: all 0.2s linear;
      box-shadow: none;
      &:hover {
        letter-spacing: 5px;
        box-shadow: 0 0 12px var(--light-blue);
      }
    }
    .auth-link {
      transition: all 0.2s linear;
      &:hover {
        letter-spacing: 3px;
      }
    }
  }
}
::v-deep .v-text-field {
  width: 100%;
  input {
    &::placeholder {
      color: rgb(255, 255, 255) !important;
      filter: brightness(0.8);
    }
  }
  fieldset {
    border: 2px solid rgb(255, 255, 255) !important;
  }
  .v-text-field__details {
    margin-bottom: 0;
  }
}
</style>
