<template>
  <v-col cols="12" sm="6" md="3" class="col px-1">
    <section class="item-card">
      <img
        src="../../../assets/images/cards/land-card-bg.png"
        class="item-card-background"
        @click="showDetails(item)"
        @load="mainBgIsLoaded"
      />
      <v-skeleton-loader
        v-if="!bgIsLoaded"
        type="image"
        class="skeleton"
        min-height="220"
      ></v-skeleton-loader>
      <img />
      <v-img
        :src="item.record_detail.art_portrait"
        contain
        class="item-card-image"
        @load="imgIsLoaded"
      >
        <v-skeleton-loader
          v-if="!imageIsLoaded"
          width="100%"
          height="100%"
          type="image"
          class="skeleton"
        ></v-skeleton-loader>
      </v-img>
      <div v-if="bgIsLoaded">
        <h5 class="item-card-name">
          {{ item.record_detail.name.substring(0, 38) }}
        </h5>
        <!-- <h5 class="item-card-size">
          <span class="">{{ item.details.size }}</span>
        </h5> -->
        <h5 class="item-card-level">
          <span class="">#0000000</span>
        </h5>
        <h5 class="item-card-price">
          <span> {{ item.price.usdt.price }}$ </span>
        </h5>
        <h5 class="item-card-price-description">
          <span v-html="item.price_description"></span>
        </h5>
      </div>
      <!-- <div class="mt-1">
        <div class="d-felx flex-column">
          <div class="d-flex align-center">
            <p class="grey--text mr-1" style="font-size: 16px">Price:</p>
            <p class="green--text mr-1" style="font-weight: 900">
              {{ item.price.usdt.price }}$
            </p>
            <span v-html="item.price_description"></span>
          </div>
          <h1 class="d-flex mt-n2">
            <p class="grey--text mr-1">Size:</p>
            <p class="orange--text">{{ item.record_detail.size }}</p>
          </h1>
        </div>
      </div>  -->
      <button class="new-shop-button " @click.stop="buyLand(item)">
        <span>Buy Now</span>
      </button>
    </section>
  </v-col>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      bgIsLoaded: false,
      imageIsLoaded: false,
    };
  },
  mounted() {
    this.handleResponsiveCardItems();
    window.addEventListener("resize", this.handleResponsiveCardItems);
  },
  methods: {
    buyLand(item) {
      this.$emit("buyLand", item);
    },
    showDetails(item) {
      this.$emit("showDetails", item);
    },
    mainBgIsLoaded() {
      console.log("loaded");
      this.bgIsLoaded = true;
    },
    imgIsLoaded() {
      this.imageIsLoaded = true;
    },

    handleResponsiveCardItems() {
      const card = document.querySelectorAll(".item-card");
      const itemCardName = document.querySelectorAll(".item-card-name");
      // console.log(card[0].clientWidth);
      card.forEach((element) => {
        if (element.clientWidth > 270) {
          itemCardName.forEach((ele) => {
            ele.style.cssText = "font-size:12px; width:70%";
          });
        } else if (element.clientWidth < 270 && element.clientWidth > 200) {
          itemCardName.forEach((ele) => {
            ele.style.cssText = "font-size:7px; width:90%";
          });
        } else {
          itemCardName.forEach((ele) => {
            ele.style.cssText = "font-size:10px; width:78%";
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.03);
  }
  .item-card {
    position: relative;
    // background: red;
    .item-card-background {
      width: 100%;
      cursor: pointer;
      position: relative;
      .skeleton {
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }
    .item-card-image {
      position: absolute;
      top: 39.5%;
      left: 10%;
      right: 10.5%;
      pointer-events: none;
      border-radius: 5px;
      transform: scaleY(1.04);
      .skeleton {
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }
    .new-shop-button {
      position: absolute;
      width: 40%;
      height: 15%;
      background: url("../../../assets/images/buttons/buy-button-background.png")
        no-repeat !important;
      background-size: cover !important;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      &:hover {
        animation: shake 0.4s linear 1;
      }
      span {
        font-size: 10px;
        font-weight: 900;
        color: #ffd600;
        text-shadow: 0px 0px 4px #ffd600;
      }
    }
    .item-card-name {
      position: absolute;
      top: 22%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
      font-weight: 9000;
      color: #000e44;
      text-align: center;
      line-height: 12px;
    }
    .item-card-size {
      position: absolute;
      top: 29%;
      right: 25%;
      span {
        color: white;
        font-size: 9px;
      }
    }
    .item-card-level {
      position: absolute;
      top: 29%;
      left: 25%;
      span {
        color: white;
        font-size: 9px;
      }
    }
    .item-card-price {
      position: absolute;
      bottom: 7%;
      left: 15%;
      span {
        color: #000e44;
        font-size: 12px;
      }
    }
    .item-card-price-description {
      position: absolute;
      bottom: 7%;
      right: 8%;
    }
  }
  .v-skeleton-loader {
    z-index: 1;
  }
}
@keyframes shake {
  0% {
    transform: translateX(-47%);
  }
  25% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(-47%);
  }
  75% {
    transform: translateX(-53%);
  }
  100% {
    transform: translateX(-47%);
  }
}
</style>
