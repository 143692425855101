<template>
  <div class="auth-wrapper">
    <section class="auth-card">
      <h2 class="text-center white--text">Login</h2>
      <p class="text-center mb-8 white--text">Login to accesss your account</p>
      <v-form @submit.prevent="confirmLogin" ref="loginForm">
        <label class="white--text">Email</label>
        <v-text-field
          type="email"
          :rules="rules.email_rules"
          v-model.trim="loginData.email"
          outlined
          dense
          dark
          placeholder="Type your email"
        >
          <v-icon slot="append">mdi-email</v-icon></v-text-field
        >

        <label class="white--text">Password</label>
        <v-text-field
          :type="`${passwordIsVisible ? 'text' : 'password'}`"
          :rules="rules.password_rules"
          v-model.trim="loginData.password"
          outlined
          dense
          dark
          placeholder="Type your password"
        >
          <v-icon
            slot="append"
            @click="passwordIsVisible = !passwordIsVisible"
            :color="passwordIsVisible ? 'white' : ''"
            >{{ passwordIsVisible ? "mdi-eye" : "mdi-eye-off" }}</v-icon
          ></v-text-field
        >
        <div class="captcha-container d-flex">
          <VueRecaptcha
            class="captch"
            @verify="successCaptcha"
            @expired="expireCaptcha"
            :sitekey="sitekey"
          />
        </div>

        <v-btn
          type="submit"
          class="login-btn mt-5 w-100"
          :loading="loading"
          :disabled="loading"
        >
          Login
          <v-icon small right color="black">mdi-login</v-icon>
        </v-btn>
      </v-form>
      <Or class="or-line"></Or>
      <GoogleLogin name="Continue with Google"></GoogleLogin>
      <div class="text-center mt-5">
        <p class="white--text">Dont have an account?</p>
        <routerLink :to="{ name: 'register' }" class="auth-link">
          Sign Up
        </routerLink>
      </div>
    </section>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { captcha } from "@/mixins/captcha.js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      loginData: {
        email: "",
        password: "",
      },
      passwordIsVisible: false,
    };
  },
  mixins: [captcha],
  components: { VueRecaptcha },
  computed: {
    ...mapGetters(["rules", "wallet_address"]),
  },

  methods: {
    ...mapActions(["login"]),

    confirmLogin() {
      if (this.$refs.loginForm.validate()) {
        if(this.captcha){
          this.loginData.recaptcha = this.captcha
          this.loading = true;
          this.login(this.loginData).then((res) => {
            if (res) {
              this.$router.push({ name: "Home" });
            }
            this.loading = false;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  height: 100vh;
  background-image: url("../../assets/images/login/login-bg(1).jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .auth-card {
    width: 98%;
    max-width: 400px;
    background: rgba(0, 0, 0, 0.838);
    border-radius: 8px;
    padding: 30px 35px;
    margin-top: 70px;
    @media only screen and(max-width : 380px) {
      transform: scale(0.9);
      padding: 20px 25px;
      margin-top: 30px;
    }
    .captcha-container {
      .captch {
        margin: 0 auto;
        @media only screen and(max-width : 380px) {
          transform: scale(0.9);
          transform-origin: left ;
        }
        @media only screen and(max-width : 350px) {
          transform: scale(0.80);
        }
      }
    }
    .login-btn {
      background: var(--light-blue) !important;
      color: black;
      font-weight: bolder;
      transition: all 0.2s linear;
      box-shadow: none;
      
      &:hover {
        letter-spacing: 5px;
        box-shadow: 0 0 12px var(--light-blue);
      }
    }
    .auth-link {
      transition: all 0.2s linear;
      &:hover {
        letter-spacing: 3px;
      }
    }
  }
}
::v-deep .v-text-field {
  width: 100%;
  input {
    &::placeholder {
      color: rgb(255, 255, 255) !important;
      filter: brightness(0.8);
    }
  }
  fieldset {
    border: 2px solid rgb(255, 255, 255) !important;
  }
  // .v-text-field__details{
  //   margin: 0;
  // }
}
</style>
